<template>
  <!-- 상품 리스트 페이지 -->
  <div class="content-main-top-container">
    <!-- 프로모션 -->
    <div class="lp-promotion" v-if="filtered.selectedMenu">
      <div :style="promotionImage"></div>
      <!--<div class="container lp-promotion-padding" style="overflow-y: hidden">
        <div class="lp-promotion-title" :class="{'lp-promotion-title-mb':isMobile}" v-html="filtered.selectedMenu.desc"></div>
        <div class="lp-promotion-desc" v-if="filtered.selectedMenu.status === 0">{{ description }}</div>
      </div>-->

    </div>
    <div class="lp-card-menu-title">
      <!-- 메뉴 -->
      <div class="lp-menu-div">
        <div class="container flex" style="overflow-y: unset">
          <div v-for="menu in menus"
               :key="'menu-'+menu.status"
               class="lp-menu unselect"
               :class="{'selected-menu':filtered.selectedMenu.status === menu.status}"
               @click="clickMenu(menu)">{{ menu.title }}</div>
        </div>
      </div>

    </div>
    <div class="lp-mobile-card-menu-title">
      <!-- 메뉴(모바일) -->
      <div class="lp-menu-div" style="background-color: white">
        <div class="flex" style="flex-wrap:wrap">
          <div v-for="menu in menus"
               :key="'menu-'+menu.status"
               class="lp-menu unselect"
               :class="{'selected-menu':filtered.selectedMenu.status === menu.status}"
               @click="clickMenu(menu)">{{ menu.title }}</div>
        </div>
      </div>

    </div>
    <div class="container" style="padding:12px 15px;margin-top:20px" v-if="products.length>0 && filtered.selectedMenu.status===0">
      <div class="theme-title">베스트 테마</div>
    </div>
    <CardProducts v-if="productsBest.length>0"
      :shadow="true" :products="productsBest"></CardProducts>

    <div ref="productlist" class="container" style="padding:12px 15px;"  v-if="products.length>0">
      <div class="theme-title" style="margin-top:20px">{{ filtered.selectedMenu.title }}</div>
      <div class="product-num" style="justify-content: space-between">
        <div>
          총 <span class="pro-num">{{product_number}}</span>개의 상품이 준비되어 있습니다.
        </div>
        <!--<div class="lp-arrow" @click="ordering">
          최신순
          <i v-if="recent===false" class="material-icons">keyboard_arrow_down</i>
          <i v-if="recent===true" class="material-icons">keyboard_arrow_up</i>
        </div>-->
      </div>
    </div>
    <CardProducts :shadow="true" :products="products"></CardProducts>
    <div class="store-footer-container">
      <div class="columns is-multiline col-wrap" >
        <div class="column is-half">
          <div class="footer-title">원하시는 테마 서비스가 없으신가요?</div>
          <div class="footer-content">
            런치팩은 고객 분들이 원하는 서비스를 런칭할 수 있게 돕기 위해 주문제작 서비스를 운영하고 있습니다.
            원하시는 테마가 없고 좀 더 많은 기능을 원하시는 경우 주문제작 서비스를 바로 신청해 보세요!
          </div>
          <div class="subscription-btn btn" @click="modalOpen">주문제작 신청하기</div>
        </div>
        <div class="column is-half" >
          <img class="banner" src="@/assets/theme-banner.png" alt="">
        </div>

      </div>
    </div>

    <sweet-modal ref="modal">
      <lp-service-inquiry-modal @closeModal="$refs.modal.close()"></lp-service-inquiry-modal>
    </sweet-modal>

  </div>

</template>

<script>
  import CardProducts from "../component/CardProducts";
  import LpServiceInquiryModal from "../modal/LpServiceInquiryModal";
  import { SweetModal } from 'sweet-modal-vue';
  import $ from 'jquery';

  export default {
    components: {
      SweetModal,
      LpServiceInquiryModal,
      CardProducts},
    name: "store",

    data() {

      return {
        products: [],
        productsBest: [],
        product_number: 0,
        description: '* 다양한 테마 상품이 앞으로도 지속적으로 출시됩니다',
        filtered: {
          selectedMenu: undefined
        },
        menus: [],
        recent:false,
        current_page: 1,
        total_page: 1
      }
    },
    created() {
      this.getMenus();

      let vm = this;
      $(window).scroll(function() {
        // 모바일
        if ($(window).innerHeight < 768){
          if(vm.current_page < vm.total_page) {
            vm.current_page = vm.current_page + 1;
            vm.filtered.page_num = vm.current_page;
            vm.getProducts('infinite');
          }
        }
        // pc
        else {
          if ($(window).scrollTop() > $(document).height() - $(window).height() - 400) {
            if(vm.current_page < vm.total_page) {
              vm.current_page = vm.current_page + 1;
              vm.filtered.page_num = vm.current_page;
              vm.getProducts('infinite');
            }
          }
        }
      });
    },

    computed: {
      isMobile() {
        if(navigator.userAgent.match(/Android/i)
          || navigator.userAgent.match(/webOS/i)
          || navigator.userAgent.match(/iPhone/i)
          || navigator.userAgent.match(/iPod/i)
          || navigator.userAgent.match(/BlackBerry/i)
          || navigator.userAgent.match(/Windows Phone/i)) return true;
        else return false;
      },
      promotionImage() {
        let style = {
          //backgroundImage: `url(${this.filtered.selectedMenu.img})`,
          backgroundImage: 'url(/static/img/img_banner_beta.jpg)',
          //backgroundSize: `${this.filtered.selectedMenu.status === 0 ? 'contain': 'cover'}`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          //backgroundPositionX: `${this.filtered.selectedMenu.status === 0 ? 'right': 'center'}`,
          backgroundPositionX: 'center',
          backgroundColor: 'white',
          /*opacity: '0.5',*/
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0
        };
        if (this.isMobile) {
          style.backgroundImage = 'url(/static/img/img_banner_beta_m.jpg)'
        }
        return style
      }
    },

    methods: {
      modalOpen(){
        this.setGa('주문제작 신청하기 (하단배너)', '클릭', '주문제작 신청하기');
        this.$refs.modal.open();
      },
      // 최신순
      ordering() {
        this.recent?this.recent=false:this.recent=true;
        let ordering = '';
        if(this.recent) ordering = 'id';
        else ordering = '-id';
        this.filtered.ordering = ordering;
        //this.getProducts('convert');
      },
      // 탭 선택
      clickMenu(menu) {
        this.setGa(`테마 카테고리(${menu.title})`, '클릭', menu.title);
        this.getMenu(menu);
      },
      getMenu(menu) {
        this.current_page = 1;
        this.filtered.page_num = 1;
        this.filtered.selectedMenu = menu;
        this.getProducts('convert');
      },
      getMenus() {
        this.$axios.get('public/launchpack/theme/category').then(res => {
          if (res.status === 200) {
            this.menus = res.data;
            this.menus[0].title = '전체 테마';
            if (this.menus.length > 0) {
              if(this.isMobile) {
                this.menus[0].desc = '<p style="margin-bottom:8px;line-height:0.8rem">합리적인 시장검증용 MVP테마</p>';
              } else {
                this.menus[0].desc = '<p>합리적인 시장검증용 MVP테마</p>';
              }
              this.menus[0].img = 'static/img/default_banner.png';
            }
            this.getMenu(this.menus[0]);
          }
        })
      },
      getProducts(type) {
        this.products = [];
        this.productsBest = [];
        let params = '';
        if(this.filtered.selectedMenu && this.filtered.selectedMenu.status > 0) {
          params = {selectedMenu: this.filtered.selectedMenu.status};
        }
        this.$axios.get('public/launchpack/theme', {
          params: params
        }).then(res => {
          if (res.status === 200) {
            if (type === 'infinite' ){
              this.products = this.products.concat(res.data.data);
            }
            else {
              this.products = res.data.data;
            }
            if(this.filtered.selectedMenu.status === 0) {
              this.productsBest = [];
              [37,39,53,49,55,42].forEach(id => {
                this.productsBest = this.productsBest.concat(this.products.filter(prod => { return prod.id === id }));
              });
            }

            this.product_number = res.data.count;
            this.cur_page = res.data.cur_page;
            this.total_page = res.data.total_page;
          }
        })
      }
    }
  }
</script>

<style scoped>
  .selected {
    color: #393e46;
    font-weight: bold;
    border-bottom: 3px solid #ff6600;
  }

  .content-main-top-container {
    background-color: rgb(247, 247, 247);
  }

  .lp-promotion {
    height: 400px;
    border-bottom: 1px solid #e6e6e6;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
  }
  .lp-card-menu-title {
    width: 100%;
    height: 49px;
    background-color: rgb(255, 255, 255);

  }

  .menu-title{
    height: 25px;
    float: left;
    line-height: 56px;
    font-size: 14px;
    width: 100%;
    color: rgb(152, 152, 152);
    cursor: pointer
  }
  .menu-title:nth-child(1){
    width: 360px;
    margin-left: 250px;
  }
  li{
    display: inline-block;
    margin-left: 30px;
  }

  .lp-promotion-title {
    font-size: 24px;
    margin-bottom: 18px;
    line-height: 40px;
    font-weight: bold;
    white-space: pre-line;
  }
  .lp-promotion-desc {
    font-size: 14px;
    height:20px;
    color:#a0a0a0;
  }

  .lp-promotion-img{
    width: 774px;
    height:667px;
    /*display: block;*/
    /*float:right;*/
    top:-200px;
    left:1311px;
    /*background-size: cover;*/
    position: absolute;
  }

  .product-num{
    display: flex;
    /*justify-content: space-around;*/
    width:100%;
    /*margin: 0 auto;*/
    margin-top:8px;
  }
  .pro-num-container{
    text-align: left;
    font-size: 14px;
    margin: 21px 0 20px 283px;
    width: 50%;
    padding-top:21px;
  }
  .pro-latest{
    text-align: right;
    font-size: 14px;
    margin: 21px 340px 20px 0;
    width: 50%;
    padding-top:21px;
  }
  .drop-down{
    width:10px;
    height:10px;
  }
  .pro-num{
    font-weight: 700;
  }
  .store-footer-container{
    width: 100%;
    background-color: #fff;
    display: flex;
    margin: 40px auto 0 auto;
  }
  .col-wrap{
    /*width: 100%;*/
    margin-bottom: -16px !important;
  }
  .store-footer-wrap{
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    float: left;
    padding-left: 235px;
  }
  .footer-img{
    width: 50%;
    /*float: right;*/
    /*padding-right: 235px;*/

  }
  .footer-title{
    font-size: 24px;
    padding-top: 38px;
    /*padding-left: 49px;*/
    font-weight: 500;
    margin-left: 278px;
  }
  .footer-content{
    font-size: 12px;
    color:#a0a0a0;
    line-height: 24px;
    white-space: pre-line;
    margin-left: 278px;
  }
  .subscription-btn{
    cursor:pointer;
    text-align: center;
    color: white;
    width: 160px;
    height: 48px;
    background-color: #ff6600;
    border-radius: 3px;
    padding: 14px 0;
    margin-left: 278px;
    margin-top: 30px;
  }

  .subscription-btn a{
    text-decoration: none;
    color:#ffffff;
    font-weight: 500;
  }
  .banner{
    background-size: cover;
    margin-bottom: -80px;

  }


  .lp-mobile-card-menu-title {
    display: none;
  }

  .theme-title {
    font-size: 1.85rem;
  }



  @media(min-width:1024px) and (max-width: 1440px){
    .footer-title,
    .footer-content,
    .subscription-btn {
      margin-left: 100px;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px) {
    .footer-title,
    .footer-content,
    .subscription-btn {
      margin-left: 20px;
    }
  }
  @media(min-width: 375px) and (max-width: 767px) {

    .footer-title,
    .footer-content,
    .subscription-btn {
      margin-left: 10px;
    }

  }

  @media(min-width: 320px) and (max-width: 374px) {

    .footer-title,
    .footer-content,
    .subscription-btn {
      margin-left: 10px;
      margin-right: 10px;
      letter-spacing: -1px;

    }
    .container{
      padding:15px;
    }
  }
  select{
    border:none;
  }

  @media(max-width: 768px){
    .lp-promotion-title{
      width:100%
    }
    .lp-promotion-padding{
      padding:20px;
    }
  }
  @media(max-width:320px){
    .lp-promotion-title{
      width:100%
    }
  }
  @media (max-width: 600px){
    .theme-title {
      font-size: 1.35rem;
    }
    .lp-promotion {
      height: 100px;
    }
    .lp-promotion-title {
      margin-bottom: 0;
    }
    .lp-promotion-desc {
      font-size: 12px;
    }
    .lp-promotion-title-mb {
      font-size: 1rem;
    }
    .lp-card-menu-title {
      display: none;
    }
    .lp-mobile-card-menu-title {
      display: block;
    }
    .lp-menu {
      font-size: 13px;
    }
    .banner{
      margin-bottom: 0;
    }
  }

  /*.lp-menu-div {
  //  border-bottom: 1px solid #eee;
  }*/

  .lp-menu {
    cursor: pointer;
    padding: 12px;
    color: #606060;
    border-bottom: 2px solid transparent;
  }

  .lp-menu:hover {
    color: #606060;
    font-weight: bold;
  }

  .lp-arrow {
    cursor: pointer;
    display: flex;
    align-items: center;
  }


  .selected-menu {
    color: #000000;
    font-weight: bold;
    border-bottom: 3px solid #ff6600;
  }



</style>
